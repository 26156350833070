import { css } from '@emotion/react';
import { fontSize, lineHeight } from '@prototyp/gatsby-plugin-gumball/mixins';

export const root = css`
  margin: 0;
  font-family: var(--font-family-secondary);
  font-weight: normal;
`;

export const base = css`
  ${root}
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};
`;

export const small = css`
  ${root}
  font-size: ${fontSize('small')};
  line-height: ${lineHeight('small')};
`;

export const tiny = css`
  ${root}
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};
`;

export const medium = css`
  ${root}
  font-size: ${fontSize('medium')};
  line-height: ${lineHeight('medium')};
`;

export const large = css`
  ${root}
  font-size: ${fontSize('large')};
  line-height: ${lineHeight('large')};
`;
export const xLarge = css`
  ${root}
  font-size: ${fontSize('xLarge')};
  line-height: ${lineHeight('xLarge')};
`;
export const xxLarge = css`
  ${root}
  font-size: ${fontSize('xxLarge')};
  line-height: ${lineHeight('xxLarge')};
`;
export const xxxLarge = css`
  ${root}
  font-size: ${fontSize('xxxLarge')};
  line-height: ${lineHeight('xxxLarge')};
`;
export const huge = css`
  ${root}
  font-size: ${fontSize('huge')};
  line-height: ${lineHeight('huge')};
`;
export const xHuge = css`
  ${root}
  font-size: ${fontSize('xHuge')};
  line-height: ${lineHeight('xHuge')};
`;
export const fluidBase = css`
  ${root}
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};
`;

export const fluidSmall = css`
  ${root}
  font-size: ${fontSize('small', 'base')};
  line-height: ${lineHeight('small', 'base')};
`;
export const fluidTiny = css`
  ${root}
  font-size: ${fontSize('tiny', 'base')};
  line-height: ${lineHeight('tiny', 'base')};
`;
export const fluidMedium = css`
  ${root}
  font-size: ${fontSize('base', 'medium')};
  line-height: ${lineHeight('base', 'medium')};
`;

export const fluidLarge = css`
  ${root}
  font-size: ${fontSize('medium', 'large')};
  line-height: ${lineHeight('medium', 'large')};
`;
export const fluidXLarge = css`
  ${root}
  font-size: ${fontSize('large', 'xLarge')};
  line-height: ${lineHeight('large', 'xLarge')};
`;
export const fluidXxLarge = css`
  ${root}
  font-size: ${fontSize('xLarge', 'xxLarge')};
  line-height: ${lineHeight('xLarge', 'xxLarge')};
`;
export const fluidXxxLarge = css`
  ${root}
  font-size: ${fontSize('xxLarge', 'xxxLarge')};
  line-height: ${lineHeight('xxLarge', 'xxxLarge')};
`;
export const fluidHuge = css`
  ${root}
  font-size: ${fontSize('xxxLarge', 'huge')};
  line-height: ${lineHeight('xxxLarge', 'huge')};
`;

export const fluidXHuge = css`
  ${root}
  font-size: ${fontSize('huge', 'xHuge')};
  line-height: ${lineHeight('huge', 'xHuge')};
`;

export const fluidSmallBoy = css`
  ${root}
  font-size: ${fontSize('xHuge', 'smallBoy')};
  line-height: ${lineHeight('xHuge', 'smallBoy')};
`;

export const fontFamily = (value?: 'primary' | 'secondary') => {
  return css`
    font-family: var(--font-family-${value ? value : 'secondary'});
  `;
};
